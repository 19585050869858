import {
    Box,
    Container, Divider, Grid, styled, Typography,
    useMediaQuery,
} from '@mui/material';
import {
    Download, InsertEmoticon as InsertEmoticonIcon,
    SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
    CancelPresentation as CancelPresentationIcon,

    AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
} from '@mui/icons-material';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import config from '../../../config/config';
import Anchor from '../foundation/Anchor';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import { CenteredFlex, Flexbox, VCenteredFlex } from '../foundation/Flex';
import {
    BGContainer, ShadowBGContainer, YellowTypography,
} from '../foundation/Containers';
import TawkTo from '../components/TawkTo';
import { WatchCVPageProps } from '../../../contractTypes/pages/WatchCVPage.types';
import Tooltip from '../foundation/Tooltip';
import Button from '../foundation/Button';
import { usePromotions } from '../../api/promotions.api';
import { promotionTypes } from '../../../common/promotion_type.enum';
import BackdropSpinner from '../foundation/BackdropSpinner';
import TextArea from '../foundation/TextArea';
import { postContactUs } from '../../api/contactus.api';
import Alert from '../foundation/Alert';
import theme from '../layout/theme';
import newAPIService from '../../api/newAPIService';
import employerToJobState from '../../../common/employer_to_job_state.enum';

const AccountStats = styled(VCenteredFlex)`
    justify-content: space-evenly;
    color: white;
    background: ${props => props.theme.palette.secondary.main};
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

type WatchCVPageState = {
    iframeLoaded: boolean;
    employerResponse: number;
};

const WatchCVPage = (props: WatchCVPageProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const iframeTimeoutId = useRef<number>(null);
    const iframeTestLoadedIntervalId = useRef<number>(null);
    const [iframeLoaded, setIframeLoaded] = React.useState(false);
    const [contactUsSuccess, setContactUsSuccess] = React.useState(false);
    const [employerResponse, setEmployerResponse] = React.useState(props.employerResponse || 0);

    const onIframeLoaded = () => {
        setIframeLoaded(true);
        clearInterval(iframeTimeoutId.current);
        clearInterval(iframeTestLoadedIntervalId.current);
    };

    const getDownloadLink = () => `${config.baseUrl}employer/cv/${props.userAppliedId}/job/${props.jobAppliedId}?safeid=${props.safeid}`;

    const updateIframeSrc = () => {
        if (!props.isAllowedToWatchUserDetails) return;

        if (iframeRef?.current) {
            iframeRef.current.src = `https://docs.google.com/gview?url=${getDownloadLink()}&embedded=true`;
        }
    };

    useEffect(() => {
        if (props.isAllowedToWatchUserDetails) {
            iframeTimeoutId.current = window.setInterval(
                updateIframeSrc, 1000 * 3,
            );
        }

        iframeTestLoadedIntervalId.current = window.setInterval(() => {
            const iframeDoc = iframeRef?.current?.contentDocument || iframeRef?.current?.contentWindow.document;
            if (iframeDoc && (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive')) {
                onIframeLoaded();
            }
        }, 1000);
    }, []);

    const getCV = () => {
        if (props.hasCV) {
            if (props.isImage && props.isAllowedToWatchUserDetails) {
                return (
                    <img alt="קורות חיים" ref="img" src={`${getDownloadLink()}&iframe=true`} style={{ width: '100%' }} />
                );
            }
            return <Box position="relative">
                <BackdropSpinner open={!iframeLoaded} style={{ position: 'absolute' }} />
                <iframe ref={iframeRef} title="קורות החיים" onLoad={onIframeLoaded}
                    onError={updateIframeSrc}
                    style={{ width: '100%', height: '700px', border: 0 }}
                    src={(!props.isAllowedToWatchUserDetails)
                        ? `${getDownloadLink()}&iframe=true` : `https://docs.google.com/gview?url=${getDownloadLink()}&embedded=true`} />
            </Box>;
        }

        return '';
    };

    const renderKeyValue = (key, value) => {
        if (value && value !== '') {
            return <Typography><b>{key} : </b>{value}</Typography>;
        }
        return '';
    };

    const renderDontHaveCV = () => {
        if (!props.hasCV) {
            return <div><CustomBR /> <Typography>
                    המשתמש הגיש מועמדות למשרה זו, אך לא הגיש קובץ קורות חיים.
            </Typography></div>;
        }

        return '';
    };

    const onShowUserDetailsClick = () => {
        if (props.isAllowedToWatchUserDetails) return;

        if (!props.totalUserCredits || props.totalUserCredits < 1) {
            window.showGlobalModal(
                'גישה לפרטי הקשר של המועמד/ת',
                `לצפייה בפרטי הקשר של המועמד/ת ${props.firstName}, יש לרכוש קרדיטים. השקעה בקרדיט 1 בלבד תאפשר לך ליצור קשר ישיר ולמצוא את הכישרון הבא לעסק שלך!`,
                {
                    footer: {
                        rightBtnClick: () => window.hideGlobalModal(),
                        rightBtnText: 'ביטול',
                        leftBtnClick: () => window.open('/user/buyCredits', '_blank'),
                        leftBtnText: 'רכישת קרדיטים',
                    },
                },

            );
            return;
        }

        window.showGlobalModal('אישור צפייה בפרטי מועמד', 'אתה עומד לצפות בפרטי מועמד בעלות של 1 קרדיט. האם אתה בטוח שברצונך להמשיך?', {
            footer: {
                rightBtnClick: () => window.hideGlobalModal(),
                rightBtnText: 'ביטול',
                leftBtnClick: async () => {
                    await usePromotions({
                        type: promotionTypes.credits,
                        amount: 1,
                        userid: props.userAppliedId,
                        jobid: props.jobAppliedId,
                    });

                    window.location.reload();
                },
                leftBtnText: 'אישור',
            },
        });
    };

    const renderCandidateDetails = () => {
        if (!props.candidate || !props.isAllowedToWatchUserDetails) {
            return <><CustomBR /><BGContainer>
                <Typography fontWeight="bold" style={{ textDecoration: 'underline' }}>פרטי המועמד</Typography>
                <CustomBR />
                <>
                    {renderKeyValue('שם הפונה למשרה', props.firstName)}
                    {renderKeyValue('מספר טלפון', <Button onClick={onShowUserDetailsClick} variant="text">*** הצג פרטים ***</Button>)}
                    {renderKeyValue('כתובת אימייל', <Button onClick={onShowUserDetailsClick} variant="text">*** הצג פרטים ***</Button>)}
                    {renderKeyValue('הודעה מהמועמד', props.candidateMessage)}
                    {renderDontHaveCV()}
                    <CustomBR />
                    <Button
                        style={{ backgroundColor: '#FFC400' }}
                        variant="contained" onClick={onShowUserDetailsClick}>
                        <Typography color="secondary" fontWeight="bold">הצג את פרטי מועמד</Typography>
                    </Button>
                </>
            </BGContainer></>;
        }

        return <><CustomBR /><BGContainer>
            <Typography fontWeight="bold" style={{ textDecoration: 'underline' }}>פרטי המועמד</Typography>
            <CustomBR />
            {props.isAllowedToWatchUserDetails
                ? <>
                    {renderKeyValue('שם הפונה למשרה', props.candidate.firstname || `${props.candidate.lastname}` || '')}
                    {renderKeyValue('מספר טלפון', props.candidate.phone)}
                    {renderKeyValue('כתובת אימייל', props.candidate.email)}
                    {renderKeyValue('הודעה מהמועמד', props.candidateMessage)}
                    {renderDontHaveCV()}
                </>
                : <Typography>יש לרכוש קרדיטים על מנת לצפות בפרטי המועמד</Typography>
            }
        </BGContainer></>;
    };

    const renderJobItem = () => <><CustomBR /><Anchor href={`/job/details/${props.jobAppliedId}`} blank>
                    לחץ כאן לצפייה במשרה המתאימה המופיעה באתר
    </Anchor></>;

    const renderDownloadCV = () => {
        if (!props.hasCV || !props.isAllowedToWatchUserDetails) return '';

        return <div>
            <CustomBR />
            <Anchor blank href={getDownloadLink()}>
                <VCenteredFlex><Download /> לחץ כאן להורדת קובץ קורות החיים </VCenteredFlex>
            </Anchor>
            <CustomBR />
        </div>;
    };

    const onEmployerResponseClick = async (response: number) => {
        setEmployerResponse(response);
        await newAPIService.get(`/employer/applicationResponse/${props.userAppliedId}/job/${props.jobAppliedId}?safeid=${props.safeid}&response=${response}}`);
    };

    const renderApplicationResponse = () => {
        const { candidate, jobAppliedId, safeid } = props;

        if (!candidate) return '';

        return (
            <div>
                <CustomBR />
                <Typography><b>חשוב! עדכנו את המועמד בקליק</b> - לחצו על האופציה המתאימה ושלחו עדכון מיידי למועמד :</Typography>
                <CustomBR />
                <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${props.userAppliedId}/job/${jobAppliedId}?safeid=${safeid}&response=2`}>
                                <CenteredFlex><InsertEmoticonIcon />&nbsp;אני אצור קשר עם המועמד בקרוב</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${props.userAppliedId}/job/${jobAppliedId}?safeid=${safeid}&response=3`}>
                                <CenteredFlex><SentimentVeryDissatisfiedIcon />&nbsp;לצערי, המועמד אינו מתאים למשרה</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${props.userAppliedId}/job/${jobAppliedId}?safeid=${safeid}&response=4`}>
                                <CenteredFlex><CancelPresentationIcon />&nbsp;המשרה אוישה</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                </Grid>
            </div>
        );
    };

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderCreditBank = () => (
        <AccountStats>
            <Flexbox alignItems="center" justifyContent="space-between" width="100%">
                <CenteredFlex>
                    <AccountBalanceWalletOutlinedIcon />
                    <Tooltip title="כמות הקרדיטים ברשותך לצפייה בקורות חיים של מועמדים. קרדיט אחד שווה לצפייה במועמד אחד.">
                        <Typography color="white">&nbsp;יתרת קרדיטים :&nbsp;&nbsp;&nbsp;</Typography>
                    </Tooltip>
                    <div>
                        <YellowTypography>{props.totalUserCredits || 0}</YellowTypography>
                    </div>
                </CenteredFlex>
                {!isSmallScreen && <CenteredFlex>
                    <Button color="primary" variant="contained">
                        <Anchor blank href="/user/buyCredits">
                            <Typography color="white">רכישת קרדיטים</Typography>
                        </Anchor>
                    </Button>
                </CenteredFlex>}
            </Flexbox>
            <CustomBR />
            {isSmallScreen && <CenteredFlex>
                <Button color="primary" variant="contained">
                    <Anchor blank href="/user/buyCredits">
                        <Typography color="white">רכישת קרדיטים</Typography>
                    </Anchor>
                </Button>
            </CenteredFlex>}
        </AccountStats>
    );

    const onSubmit = async data => {
        window.showBackdrop();
        await postContactUs({
            ...data,
            email: props.user?.email,
            subject: `משוב ממעסיק - ${props.user?.firstname} ${props.user?.lastname}`,
            fullname: props.user ? `${props.user?.firstname} ${props.user?.lastname}` : '',
        });
        window.hideBackdrop();
        setContactUsSuccess(true);
    };

    const {
        register, handleSubmit,
    } = useForm();

    return (
        <Page pageHeaderTitle="Mploy - צפייה במועמדות למשרה" url={props.url} user={props.user}>
            <TawkTo />
            <Container>
                <CustomBR />
                <CenteredFlex>
                    <Typography color="secondary" fontWeight="bold" variant="h4">התקבלה מועמדות חדשה&nbsp;</Typography>
                    <Anchor color="secondary" fontWeight="bold" variant="h4" href={`/job/details/${props.jobAppliedId}`} blank style={{ textDecoration: 'underline' }}>
                        <>למשרה - {props.job.title} {props.job.employerJobid}</>
                    </Anchor>
                </CenteredFlex>
                {/* {renderJobItem()} */}
                <CustomBR />
                {renderCreditBank()}
                {/* {renderApplicationResponse()} */}
                {renderCandidateDetails()}
                {renderDownloadCV()}
                <CustomBR />
                <Divider />
                <CustomBR />
                <BGContainer>
                    {employerResponse === 2 && !props.isAllowedToWatchUserDetails
                        && <Grid container spacing={2}>
                            <Grid item md={10} sm={12}>
                                <Flexbox flexDirection="column">
                                    <Typography color="secondary" fontWeight="bold" variant="body1">
                            אל תתן ל{props.firstName || 'מועמד'} להמתין יותר מדי!
                                    </Typography>
                                    <Typography color="secondary" variant="body1">
                            כדי לצפות בפרטי הקשר של {props.firstName || 'המועמד'},
                            נדרש קרדיט אחד בלבד – השקעה קטנה שיכולה להוביל להזדמנות גדולה.
                                    </Typography>
                                </Flexbox>
                            </Grid>
                            <Grid item md={2} sm={12} textAlign="left">
                                <Button
                                    style={{ backgroundColor: '#FFC400' }}
                                    variant="contained" onClick={onShowUserDetailsClick}>
                                    <Typography color="secondary" fontWeight="bold">הצג את פרטי מועמד</Typography>
                                </Button>
                            </Grid>
                        </Grid>}
                    {employerResponse === 2 && props.isAllowedToWatchUserDetails && <Typography
                        color="secondary" fontWeight="bold" variant="body1">
                            תודה רבה על המשוב החיובי!
                            שמחים לשמוע שהמועמד רלוונטי עבורך – נמשיך לעבוד כדי להביא לך מועמדים מדויקים ואיכותיים נוספים.
                    </Typography>}
                    {employerResponse === 3 && <Typography color="secondary" fontWeight="bold" variant="body1">
                            ציינת שהמועמד אינו מתאים – תודה על המשוב! נחדד את ההתאמות הבאות בהתאם למה שציינת, כדי שתקבל מועמדים מדויקים יותר.
                    </Typography>}
                    {(!employerResponse || employerResponse <= employerToJobState.WATCHED) && <Grid container spacing={2}>
                        <Grid item md={6} sm={12}>
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained" onClick={() => onEmployerResponseClick(2)}>
                                <Flexbox>
                                    <Typography color="white" fontWeight="bold">המועמד רלוונטי למשרה&nbsp;</Typography>
                                    <InsertEmoticonIcon />
                                </Flexbox>
                            </Button>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Button
                                fullWidth
                                style={{ backgroundColor: '#b91f0b' }}
                                variant="contained" onClick={() => onEmployerResponseClick(3)}>
                                <Flexbox>
                                    <Typography color="white" fontWeight="bold">המועמד לא רלוונטי למשרה&nbsp;</Typography>
                                    <SentimentVeryDissatisfiedIcon />
                                </Flexbox>
                            </Button>
                        </Grid>
                    </Grid>}
                </BGContainer>
                <div style={{ textAlign: 'center' }}>
                    <CustomBR />
                    {props.isAllowedToWatchUserDetails ? <Typography color="secondary" fontWeight="bold" variant="h5">קורות חיים</Typography>
                        : <Flexbox>
                            <Typography color="secondary" fontWeight="bold" variant="h5">קורות חיים</Typography>
                            <Typography lineHeight={2.5} color="secondary" fontWeight="bold" variant="body2">&nbsp;(פורמט גולמי)</Typography>
                        </Flexbox>}
                    <CustomBR />
                    {getCV()}
                </div>
                <CustomBR />
                <CustomBR />
                {contactUsSuccess ? <Alert severity="success">ההודעה נשלחה בהצלחה! נחזור אליך במהרה.</Alert> : <Box>
                    <Typography fontWeight="bold" variant="h5">שתף אותנו במשוב קצר</Typography>
                    <CustomBR />
                    <Typography variant="body2">
                        נשמח לשמוע את דעתך בכל נושא, ולעזור בתהליך הגיוס,
                        אנו זמינים <Anchor blank href="https://wa.me/0544700720">גם בוואסאפ - לחצו כאן לשליחת הודעה</Anchor>.
                    </Typography>
                    <CustomBR />
                    <div>
                        <TextArea
                            required
                            inputRef={register({ required: true })}
                            name="message"
                            id="message"
                            label="הודעה"
                            type="text"
                            fullWidth
                            rows={2}
                        />
                    </div>
                    <CustomBR />
                    <Button onClick={handleSubmit(onSubmit)} color="primary" fullWidth>שלח הודעה</Button>
                </Box>}
                <CustomBR />
                <CustomBR />
                <Box>
                    <Typography fontWeight="bold" variant="h5">פעולות נוספות</Typography>
                    <CustomBR />
                    <Typography fontWeight="bold" variant="body1">
                            המשרה כבר אוישה?
                        <Anchor blank href={`/employer/applicationResponse/${props.userAppliedId}/job/${props.jobAppliedId}?safeid=${props.safeid}&response=4`}>
                                &nbsp;לחץ כאן להסרת המשרה
                        </Anchor>
                    </Typography>
                </Box>
                <CustomBR />
                <CustomBR />
            </Container>
        </Page>
    );
};

export default WatchCVPage;
