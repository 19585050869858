import {
    PersonOutline, Person, BusinessCenter,
    AccountBoxOutlined as AccountBoxOutlinedIcon,
    HomeOutlined, ExitToApp, InfoOutlined,
    ChevronLeft, BusinessCenterOutlined,
} from '@mui/icons-material';

import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    Container, Grid, Hidden, Popover,
} from '@mui/material';
import { LoggedInUser } from '../../../../types/auth.types';
import UserContext from '../../context/user.context';
import Anchor from '../../foundation/Anchor';
import Button from '../../foundation/Button/Button';
import { vCenteredFlex } from '../../foundation/Flex';
import Logo from '../../foundation/Logo';
import LoginModal from '../../modals/LoginModal/LoginModal';
import ResetPasswordModal from '../../modals/ResetPasswordModal/ResetPasswordModal';
import RegisterModal from '../../modals/RegisterModal/RegisterModal';
import { removeURLParameter } from '../../../utils/urlUtils';
import NavbarMobile from './NavbarMobile';
import CustomBR from '../../foundation/CustomBR';

export type NavbarProps = {
    logoutClick: (ev) => void
}

type NavItem = {
    onClick?: (ev) => void
    text: string,
    url?: string,
    icon?: string,
    iconEl?: JSX.Element,
    navItems?: {
        text: string,
        url: string,
    }[]
}

const getMenu = (user?: LoggedInUser) => {
    const menu: NavItem[] = [{
        text: 'דף הבית',
        url: '/',
        iconEl: <HomeOutlined />,
    }];

    if (user) {
        menu.push({
            text: 'הפרופיל שלי',
            url: '/user/profile',
            iconEl: <AccountBoxOutlinedIcon />,
        }, {
            text: 'מועמד',
            iconEl: <PersonOutline />,
            navItems: [{
                text: 'ניהול קורות חיים',
                url: '/user/cvs',
            }, {
                text: 'משרות שפניתי אליהן',
                url: '/user/appliedJobs',
            }, {
                text: 'המשרות השמורות שלי',
                url: '/user/savedJobs',
            }, {
                text: 'הגדרות וסוכן חכם',
                url: '/user/settings',
            }],
        }, {
            text: 'מעסיק',
            iconEl: <BusinessCenterOutlined />,
            navItems: [{
                text: 'ניהול משרות',
                url: '/user/postedJobs',
            }, {
                text: 'פרסום משרות',
                url: '/user/buyJobs',
            }, {
                text: 'רכישת קרדיטים',
                url: '/user/buyCredits',
            }, {
                text: 'רכישת משרות מקודמות',
                url: '/user/buyPromotedJobs',
            }],
        });

        // if (user.roles && user.roles.indexOf(rolesEnum.Registered) !== -1) {
        //     menu[3].navItems.push({
        //         text: 'פרסם משרה בחינם',
        //         url: '/user/postJobs',
        //     });
        // }
    }

    if (user && user.admin) {
        menu[3].navItems.push({
            text: 'חיפוש מועמדים (Premium)',
            url: '/employer/searchcv',
        }, {
            text: 'חיפוש מועמדים ברשת (VIP)',
            url: '/employer/searchLeads',
        }, {
            text: 'ניהול משתמשים',
            url: '/admin/users',
        }, {
            text: 'ניהול משרות מקודמות',
            url: '/admin/promotedJobs',
        }, {
            text: 'ניהול משרות שפורסמו ע"י משתמשים',
            url: '/admin/internalJobs',
        }, {
            text: 'ניהול משרה',
            url: '/admin/jobs',
        }, {
            text: 'ניהול חברות',
            url: '/admin/companies',
        });
    }

    menu.push({
        text: 'אודות',
        url: '/about/show',
        iconEl: <InfoOutlined />,
    });

    return menu;
};

export type NavItemProps = NavItem

const NavItemStyled = styled.div<{ selected?: boolean }>`
    position: relative;
    a {
        cursor: pointer;
        color: ${props => (props.selected ? props.theme.palette.primary.main : '#adb9c1')};
        text-decoration: none;
        font-weight: 500;
        &:hover {
            text-decoration: none;
            color: ${props => props.theme.palette.primary.main}
        }
    }
    svg {
        fill: ${props => props.theme.palette.primary.main};
    }
    > svg {
        margin-inline-end: 0.8rem;
        margin-bottom: -0.5rem;
    }

    ${props => props.theme.breakpoints.up('md')} {
        ${vCenteredFlex}
        margin-inline-end: 3rem;
        > div {
            max-height: 0;
            overflow: hidden;
        }
        /* &:hover {
            > div {
                transition: max-height 0.3s ease-in;
                max-height: 10rem;
            }
            padding: 2rem 0;
        } */
    }
    ${props => props.theme.breakpoints.down('md')} {
        padding-bottom: 1.5rem;
        a {
            color: ${props => props.theme.palette.secondary.main};
        }
    }
`;

const SubMenuStyle = styled.div`
    ${props => props.theme.breakpoints.down('md')} {
        margin-inline-start: 2rem;
        > div {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    ${props => props.theme.breakpoints.up('md')} {
        /* position: absolute; */
        /* top: 70px; */
        inset-inline-start: -6px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        background-color: #f9f9fb;
        width: 14rem;
        border-radius: 15px;
        > div {
            ${vCenteredFlex}
            cursor: pointer;
            a, a:hover {
                opacity: 0.7;
                color: ${props => props.theme.palette.secondary.main};
            }
            width: 100%;
            padding-inline-start: 1.5rem;
            &:hover {
                background: #ececec;
            }
            > svg {
                margin-inline-start: auto;
            }
        }
        > div:first-child {
            margin-top: 1rem;
        }
        > div:last-child {
            margin-bottom: 1rem;
        }
    }
`;

const NavItemDesktop = (props: NavItemProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    return <NavItemStyled>
        {props.iconEl && <Hidden mdUp>{props.iconEl}</Hidden>}
        <Anchor onClick={(ev: any) => {
            if (props.navItems) setAnchorEl(ev.currentTarget);
            else if (props.onClick) props.onClick(ev);
        }} variant="subtitle1" text={props.text + (props.navItems ? ' ▾' : '')} href={props.url} />
        {props.navItems && <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            elevation={0}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
                style: { background: 'none', marginTop: '10px' },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <SubMenuStyle>
                {props.navItems.map(item => <div key={item.text}>
                    <Anchor variant="subtitle1" text={item.text} href={item.url} />
                    <ChevronLeft />
                </div>)}
            </SubMenuStyle>
        </Popover> }
    </NavItemStyled>;
};

const NavItemMobile = (props: NavItemProps): JSX.Element => <NavItemStyled>
    {props.iconEl && <Hidden mdUp>{props.iconEl}</Hidden>}
    <Anchor onClick={props.onClick} variant="subtitle1" text={props.text + (props.navItems ? ' ▾' : '')} href={props.url} />
    {props.navItems && <SubMenuStyle>
        {props.navItems.map(item => <div key={item.text}>
            <Anchor variant="subtitle1" text={item.text} href={item.url} />
            <ChevronLeft />
        </div>)}
    </SubMenuStyle>}
</NavItemStyled>;

const NabvarStyled = styled.div`
    ${vCenteredFlex}
    margin: 1rem;
    div:last-child {
        margin-inline-start: auto;
    }
`;
const DesktopNavbarStyled = styled.div`
    ${vCenteredFlex}
`;

const Nabvar = (props: NavbarProps): JSX.Element => {
    const user = useContext(UserContext);
    const [loginModal, setLoginModal] = useState<boolean>(false);
    const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
    const [registerModal, setRegisterModal] = useState<boolean>(false);
    const [showSmartAgentRegisterModal, setShowSmartAgentRegisterModal] = useState<boolean>(false);
    const [employerRegisterModal, setEmployerRegisterModal] = useState<boolean>(false);

    useEffect(() => {
        window.modals = {
            ...(window.modals || {}),
            toggleLoginModal: () => setLoginModal(!loginModal),
            toggleRegisterModal: ({ hideSmartAgent } = {}) => {
                const showRegisterModal = !registerModal;
                setRegisterModal(showRegisterModal);
                if (hideSmartAgent && showRegisterModal) { setShowSmartAgentRegisterModal(false); }
            },
            toggleEmployerRegisterModal: () => setEmployerRegisterModal(!registerModal),
        };
    }, []);

    const onLoginClick = () => setLoginModal(true);
    const onRegisterClick = () => { setRegisterModal(true); };
    const onEmployerRegisterClick = () => {
        window.location.assign('/user/buyJobs');
        // window.history.pushState({}, '', updateQueryStringParameter('redirect', '/user/postedJobs'));
        // setEmployerRegisterModal(true);
    };

    return <Container>
        <LoginModal
            gotoRegister={() => { setLoginModal(false); setRegisterModal(true); }}
            onResetPasswordClick={() => { setLoginModal(false); setResetPasswordModal(true); }}
            onClose={() => setLoginModal(false)} open={loginModal} />
        <RegisterModal
            showSmartAgent={showSmartAgentRegisterModal}
            gotoLogin={() => { setRegisterModal(false); setLoginModal(true); }}
            onClose={() => setRegisterModal(false)} open={registerModal} />
        <RegisterModal
            welcomeText={`
                מעסיק? הגעת למקום הנכון, ב-Mploy פרסום משרה מתבצע בכמה קליקים.
                תחילה יש לבצע הרשמה קצרה - זה פשוט וקל! מיד לאחר מכן תוכל לרכוש חבילה ולפרסם המשרה.
            `}
            showSmartAgent={false}
            gotoLogin={() => { setEmployerRegisterModal(false); setLoginModal(true); }}
            onClose={() => {
                setEmployerRegisterModal(false);
                window.history.pushState({}, '', removeURLParameter('redirect'));
            }} open={employerRegisterModal} />
        <ResetPasswordModal onClose={() => setResetPasswordModal(false)} open={resetPasswordModal} />
        <NabvarStyled>
            <Hidden mdDown>
                <DesktopNavbarStyled>
                    {getMenu(user).map(navItem => <NavItemDesktop {...navItem} key={navItem.text} />)}
                    {user ? <div>
                        <Button onClick={props.logoutClick} size="small" color="secondary">
                                התנתק
                        </Button>
                    </div> : <Grid container width="initial" spacing={2}>
                        <Grid item><Button
                            startIcon={<Person />}
                            onClick={onLoginClick}
                            size="large" color="secondary">התחברות</Button></Grid>
                        <Grid item><Button
                            onClick={onRegisterClick}
                            startIcon={<PersonOutline />}
                            size="large" color="primary">
                            הרשמה
                        </Button></Grid>
                        <Grid item>
                            <Button
                                onClick={onEmployerRegisterClick}
                                startIcon={<BusinessCenter />}
                                size="large" color="primary">
                                גיוס עובדים
                            </Button>
                        </Grid>
                    </Grid>}
                </DesktopNavbarStyled>
            </Hidden>
            <Hidden mdUp>
                <NavbarMobile>
                    <>
                        {getMenu(user).map(navItem => <NavItemMobile {...navItem} key={navItem.text} />)}
                        {!user ? <>
                            <CustomBR />
                            <Button
                                fullWidth
                                onClick={onLoginClick}
                                size="large" color="secondary">התחברות</Button>
                            <CustomBR times={2} />
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={onRegisterClick}
                                size="large" color="secondary">הרשמה</Button>
                            <CustomBR times={2} />
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={onEmployerRegisterClick}
                                size="large" color="secondary">גיוס עובדים</Button>
                        </> : <NavItemMobile iconEl={<ExitToApp />} onClick={props.logoutClick} text="התנתק" url="/" key="logout" />}
                    </>
                </NavbarMobile>
            </Hidden>
            <Logo />
        </NabvarStyled>
    </Container>;
};

export default Nabvar;
