const employerToJobState = {
    NOT_SENT: -1,
    SENT: 0,
    WATCHED: 1,
    EMPLOYER_WILL_CONTACT: 2,
    CANDIDATE_DOES_NOT_MATCH: 3,
    JOB_OCCUPIED_ALREADY: 4,
} as const;

export default employerToJobState;
