import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyleSheetManager, ThemeProvider as StyledThemeProvider } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';

import theme from './theme';
import { UserProvider } from '../context/user.context';
import { LoggedInUser } from '../../../types/auth.types';

export type AppRootProps = {
    children: JSX.Element | JSX.Element[]
    user?: LoggedInUser
}

const RTL = props => (
    <StyleSheetManager stylisPlugins={[rtlPlugin]}>
        <>{props.children}</>
    </StyleSheetManager>
);

const AppRoot = (props: AppRootProps): JSX.Element => <>
    <CssBaseline />
    <UserProvider value={props.user}>
        <StyledThemeProvider theme={theme}>
            <RTL>
                <ThemeProvider theme={theme}>
                    {props.children}
                </ThemeProvider>
            </RTL>
        </StyledThemeProvider>
    </UserProvider>
</>;

export default AppRoot;
