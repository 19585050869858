import {
    Container, Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { LoggedInUser } from '../../../types/auth.types';
import { BGContainer, WhiteBGContainer } from '../foundation/Containers';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import Anchor from '../foundation/Anchor';

export type AccountVerifiedProps = {
    url: string
    user?: LoggedInUser
    title: string
    img: string
    htmlContent?: string
}

const AccountVerifiedStyle = styled.div`
`;

const AccountVerified = (props: AccountVerifiedProps): JSX.Element => <Page
    showBreadCrumbs
    url={props.url}
    user={props.user}
    breadCrumbs={[{
        text: props.title,
        url: props.url,
    }]}
    pageHeaderTitle={`Mploy - ${props.title}`} >
    <Container>
        <AccountVerifiedStyle>
            <CustomBR />
            <BGContainer>
                <Typography color="secondary" fontWeight="bold" variant="h4">Mploy - {props.title}</Typography>
                <CustomBR />
                <WhiteBGContainer>
                    <Typography color="secondary" fontWeight="bold" variant="h5">ברוך הבא לפלטפורמת Mploy</Typography>
                    <CustomBR />
                    <Typography color="secondary" variant="body1">אנו שמחים שהחלטת לקחת חלק בפלטפורמה שלנו ומקווים שתהנה מהשימוש בה 😊</Typography>
                    <CustomBR />
                    {!props.user?.isEmployer && <>
                        <Typography color="secondary" fontWeight="bold">קישורים חשובים למועמדים</Typography>
                        <CustomBR />
                        <Anchor href="./user/settings">חסוך זמן והגדר סוכן חכם</Anchor> - קבלת משרות בבוקר לפי תחומים ותפקידים שהגדרת
                        <CustomBR h={5} />
                        <Anchor href="https://chat.whatsapp.com/FdTeLEGHXkX0qt942bG6GR">הצטרפו אלינו גם בוואטסאפ</Anchor> - משרות עבודה בקבוצות וואטסאפ שקטות
                        <CustomBR h={5} />
                        <Anchor href="./user/profile">הפרופיל שלי</Anchor>
                        <CustomBR />
                    </>}
                    <Typography color="secondary" fontWeight="bold">קישורים חשובים למעסיקים</Typography>
                    <CustomBR />
                    <Anchor href="./user/postedJobs">פרסום משרות</Anchor> - פאנל ניהול משרות ומועמדים למעסיקים, התחל עכשיו בפרסום משרות.
                    <CustomBR h={5} />
                    <Anchor href="./user/profile">הפרופיל שלי</Anchor>
                </WhiteBGContainer>
                <CustomBR />
            </BGContainer>
        </AccountVerifiedStyle>
    </Container>
    <CustomBR />
</Page>;

export default AccountVerified;
